<template>

    <div v-if="content.showTcpa" class="m-5 tcpa">

        <label for="leadid_tcpa_disclosure">
            <input id="leadid_tcpa_disclosure" type="hidden">
            <div v-if="descriptionHTML" class="mb-5 text-gray-500 text-sm text-center" data-cy="tcpa-description" v-html="descriptionHTML"/>

            <span class="text-xs text-justify" v-html="tcpaHtml"/>
        </label>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { WebFormStep } from '../../../types/State'
    import { tcpa } from '../../../types/TCPA'

    export default defineComponent({
        name: 'TCPA',
        props: {
            content: { type: Object as PropType<WebFormStep[ 'content' ]>, required: true },
        },
        setup() {

            const tcpaHtml = computed(() => tcpa.content)
            const descriptionHTML = computed(() => tcpa.description)
            const partnersList = computed(() => tcpa.partnersList)

            return {
                tcpaHtml,
                descriptionHTML,
                partnersList,
            }

        },

    })

</script>

<style lang="scss">

    .tcpa span span.modal-link {
        @apply text-blue-400 font-semibold underline cursor-pointer
    }

</style>
