<template>

    <div class="flex flex-col bg-gray-100" :class="theme">

        <TransitionTopToBottom v-if="!isHeaderLess">

            <nav v-if="showNavbar" class="bg-white border-b border-gray-200 z-10">

                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

                    <div class="flex items-center justify-between h-16">

                        <div class="flex-shrink-0 flex items-center">
                            <LayoutDynamicLogo :logo="state.domain.content.logo"/>
                        </div>

                        <LayoutPhoneBox :number="phoneNumber" :text="phoneText"/>

                    </div>

                </div>

            </nav>

        </TransitionTopToBottom>

        <div id="root"/>

        <main class="flex-1 relative"
              :class="{
                  'mt-24': !isStoryBlokLandingActive && !center && !isChat,
                  'min-h-screen': !isHeaderLess && !isStoryBlokLandingActive && !isChat,
                  'flex items-center': center && !isStoryBlokLandingActive,
                  'py-8 md:py-16': (!center || isHeaderLess) && !isStoryBlokLandingActive,
              }">

            <Layout>

                <RouterView v-slot="{ Component, route }" @without-navbar="showNavbar = false">

                    <TransitionLeftToRight>

                        <Suspense>

                            <Component :is="Component" :key="route.name"/>

                            <template #fallback>
                                Loading...
                            </template>

                        </Suspense>

                    </TransitionLeftToRight>

                </RouterView>

                <template #footer>
                    <LayoutBanner v-if="useBanner" :phone="phoneNumber"/>
                </template>

                <template #consent>
                    <LayoutConsentModal v-if="showConsentModal"/>
                </template>

            </Layout>

        </main>

        <LayoutFooter v-if="!isFooterLess" ref="footerReference"/>

    </div>

</template>

<script lang="ts">

    import { HookRunner } from './lib/HookRunner'
    import { Hook } from './lib/enums/Hook'
    import { RouteKey } from './lib/enums/RouteKey'
    import { computed, defineComponent, nextTick, onMounted, onUnmounted, provide, ref, watch } from 'vue'
    import LayoutFooter from './components/layout/LayoutFooter.vue'
    import Layout from './components/layout/Layout.vue'
    import LayoutBanner from './components/layout/LayoutBanner.vue'
    import LayoutConsentModal from './components/layout/LayoutConsentModal.vue'
    import TransitionLeftToRight from './components/transitions/TransitionLeftToRight.vue'
    import LayoutDynamicLogo from './components/layout/LayoutDynamicLogo.vue'
    import { useRoute, useRouter } from 'vue-router'
    import { Icon } from '@iconify/vue'
    import { isStoryBlokLandingActive, state } from './types/State'
    import { isBlank, postMessage, smoothScrollTo } from './lib/Helpers'
    import { Ringba } from './lib/Ringba'
    import TransitionTopToBottom from './components/transitions/TransitionTopToBottom.vue'
    import ResizeObserver from 'resize-observer-polyfill'
    import { ModalLinkHandlerKey } from './types/InjectKeys'
    import LayoutPhoneBox from './components/layout/LayoutPhoneBox.vue'
    import { AudioManager } from './lib/AudioManager.js'

    export default defineComponent({
        name: 'App',
        components: {
            LayoutPhoneBox,
            TransitionTopToBottom,
            TransitionLeftToRight,
            LayoutDynamicLogo,
            LayoutBanner,
            LayoutConsentModal,
            LayoutFooter,
            Layout,
            Icon,
        },
        setup() {

            const route = useRoute()
            const router = useRouter()
            const hookRunner = new HookRunner()
            const phoneNumber = new Ringba().getPhoneNumber()
            const useBanner = computed(() => route.meta.useBanner && state.webForm.content.showStickyBar)
            const center = computed(() => route.meta?.center?.value === true)
            const showNavbar = ref(true)
            const phoneText = state.webForm.content?.phoneText ?? state.domain.content.phoneText
            const isFooterLess = state.webForm.content.footerless || state.domain.content.footerless
            const isHeaderLess = state.webForm.content.headerless || state.domain.content.headerless
            const showConsentModal = state.webForm.content.consentModal ?? true
            const isChat = computed(() => route.meta?.isChat === true)
            const audioManager = new AudioManager()

            provide('audioManager', audioManager)

            const theme = computed(() => {

                const classes = isBlank(state.domain.theme)
                    ? [
                        'primary-blue',
                        'secondary-red',
                        'button-orange',
                    ]
                    : [
                        `primary-${ state.domain.theme?.content.color.primary }`,
                        `secondary-${ state.domain.theme?.content.color.secondary }`,
                        `button-${ state.domain.theme?.content.color.button }`,
                    ]

                classes.push('min-h-[100dvh]')

                return classes

            })

            const footerReference = ref()

            function processEvent(event: Event): void {

                if (event.target instanceof HTMLElement) {

                    const id = event.target.getAttribute('data-modal')

                    if (id) {

                        footerReference.value?.showDynamicModalById(id)
                        postMessage('modalLink', { id })

                    }

                }

            }

            function handlers(toggle: boolean): void {

                for (const element of Array.from(document.getElementsByClassName('modal-link'))) {

                    toggle
                        ? element.addEventListener('click', processEvent)
                        : element.removeEventListener('click', processEvent)

                }

            }

            onMounted(() => {

                router.beforeEach(to => {

                    handlers(false)

                    if (RouteKey.WebForm === to.name && !state.webForm.formPlanner.groups.length) {

                        const routeKey = state.webForm.content.bypassLoadingPage
                            ? RouteKey.ThankYou
                            : RouteKey.Loading

                        nextTick(() => {

                            router.push({
                                name: routeKey,
                                params: to.params,
                                query: to.query,
                            })

                        })

                    }

                })

                router.afterEach(() => {

                    showNavbar.value = true

                    nextTick(() => {

                        setTimeout(() => handlers(true), 400)

                        /**
                         * Prevent the page from scrolling to top when the next route is an inline web form step.
                         * This is because the inline web form can be rendered below the fold, and scrolling
                         * the page to the top after each step wouldn't make sense.
                         */
                        if (route.name !== RouteKey.InlineWebForm) {
                            smoothScrollTo()
                        }

                        hookRunner.run(Hook.VueReady, { ...state.domain.metadata, ...state.webForm.metadata })
                        postMessage('pageChanged', { name: route.name, params: route.params })

                    })

                })

                nextTick(() => postMessage('heightChanged', { height: document.body.clientHeight }))

                new ResizeObserver(
                    entries => postMessage('heightChanged', { height: entries[ 0 ].target.clientHeight }),
                ).observe(document.body)

                hookRunner.run(Hook.DOMContentLoaded, { ...state.domain.metadata, ...state.webForm.metadata })

            })

            /*
             * Sometimes the modal-link elements exist within dynamically rendered content,this gives us the ability
             * To ensure the event handlers are set up after the content is rendered.
             */
            provide(ModalLinkHandlerKey, handlers)
            onUnmounted(() => handlers(false))

            return {
                state,
                isStoryBlokLandingActive,
                theme,
                isHeaderLess,
                isFooterLess,
                isChat,
                phoneNumber,
                phoneText,
                center,
                useBanner,
                showNavbar,
                footerReference,
                showConsentModal,
                audioManager,
            }

        },
    })

</script>

<style lang="scss">

    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    @import 'styles/ThemeBlue.css';
    @import 'styles/ThemeRed.css';
    @import 'styles/ThemeOrange.css';
    @import 'styles/ThemeIndigo.css';
    @import 'styles/ThemePink.css';
    @import 'styles/ThemeYellorange.css';

    /**
     * Animations
     */
    .animate-throb {
        animation: throb 1s ease-in-out infinite alternate;
    }

    @keyframes throb {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.05);
        }
    }

    body {
        overflow: overlay;
    }

</style>
